export default function (authContext, loginState, authConfig, location, logger) {
	this.redirectToLoginPage = () => {
		const newLogin = loginState.startLogin();
		logger.info(`Starting login. State: ${newLogin.state} Nonce: ${newLogin.nonce} ${new Date()}`);

		const currentLocation = location.absUrl();
		logger.info("Redirecting to login page with return url", currentLocation);
		location.replace(authConfig.loginUrlFor(newLogin, currentLocation));
	};

	this.redirectToLoginPageReuseCurrentLogin = () => {
		const newLogin = loginState.currentLogin();
		logger.info(`Starting login. State: ${newLogin.state} Nonce: ${newLogin.nonce} ${new Date()}`);

		const currentLocation = location.absUrl();
		const loginUrl = authConfig.loginUrlFor(newLogin, currentLocation);
		logger.info("Redirecting to login page with return url", currentLocation);
		location.replace(authConfig.loggedOutPageUrlWithReturn(loginUrl));
	};

	this.redirectToLogoutPage = (returnUrl, isFirstEntrance, reason) => {
		logger.info("Redirecting to logout page with return url", returnUrl);
		const idToken = authContext.getIdToken();
		authContext.logout();
		location.replace(authConfig.logoutUrlWithReturn(returnUrl, isFirstEntrance, reason, idToken));
	};

	this.redirectToLogoutPageAndReturnToCurrentPage = (isFirstEntrance) => {
		const currentLocation = location.absUrl();
		this.redirectToLogoutPage(currentLocation, isFirstEntrance);
	};
	this.redirectToLogoutPageAndReturnToMainPage = (isFirstEntrance, reason) => {
		const mainPage = location.origin();
		this.redirectToLogoutPage(mainPage, isFirstEntrance, reason);
	};
}
